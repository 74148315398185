//@import "1-vendor/vanillatoasts.css";
/*
@import "1-vendor/bulma/utilities/_all";
@import "1-vendor/bulma/form/shared";
@import "1-vendor/bulma/form/input-textarea";
*/
//zzz @import "1-vendor/bulma/bulma.sass";

//zzz @import "2-helpers/_fonts.scss";
//zzz @import "2-helpers/_variables.scss";
//@import "3-base/_normalize";
//zzz @import "4-layout/_main.scss";
//zzz @import "5-components/_basics.scss";
/*@import "6-pages!**!*.scss";*/

body div {
    box-sizing: border-box;
}